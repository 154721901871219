import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { FileDto, SwaggerException } from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import { AppConsts } from '@/abpPro/AppConsts';
import { throwError } from "rxjs";
import { UrlHelper } from "../../../helpers";
import { message } from "ant-design-vue";
import { formatInternalValue } from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";
import entityHelper from "@/shared/helpers/EntityHelper.js"

let workflowApi = {
    namespaces: 'workflow',
    instance: apiHttpClient,
    locked: false,
    localStore: {},
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getContainerMode(taskType) {
        switch (taskType) {
            case 1:
            case 3:
                return "view";
            default:
                return "audit";
        }
    },
    async create(code, processId, title, level, auditors, forms) {

        // {
        //     "code": "string",
        //     "processId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "title": "string",
        //     "level": 0,
        //     "auditors": "string",
        //     "form": {
        //     "schemeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //         "mainTableId": "string",
        //         "fromData": {
        //         "additionalProp1": {},
        //         "additionalProp2": {},
        //         "additionalProp3": {}
        //     }
        // }
        // }
        let data = {
            "code": code,
            "processId": processId,
            "title": title,
            "level": level,
            "auditors": JSON.stringify(auditors),
            "Forms": forms
        }

        if (!data.processId) {
            console.log("newguid!!")
            data.processId = entityHelper.newGuid();
        }
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/create";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: data
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    async auditFlow(opCode, name, processId, taskId, des, auditors) {
        let data = {
            "operationCode": opCode,
            "operationName": name,
            "processId": processId,
            "taskId": taskId,
            "auditors": JSON.stringify(auditors),
            "des": des
        }
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/auditFlow";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: data
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    getPagedList: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetPaged";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    getScheme: async function (id) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/workflowScheme/GetEditById";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, { id: id }),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },

    getById: async (itemId) => {

    },

    getNodeSchemeByNodeId: async function (processId, nodeId) {
        console.log('getByCode')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/Workflow/getNodeScheme";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, { processId: processId, nodeId: nodeId }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
        }
        return _response.data
    },
    getStartNode: async function (code) {
        console.log('getByCode')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/Workflow/GetStartNode";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, { code: code }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
        }
        return _response.data
    },
    getByCode: async function (code) {
        console.log('getByCode')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetFlowParam";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, { code: code }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    async save(data) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/SaveWorkflowSchemeInfo";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: data
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    }
}

export default workflowApi