<template>
    <a-spin :spinning="spinning">
        <!-- <page-header title="流程模板管理"></page-header> -->
            

                <!-- <react-table
                    :actions-type="actionsType"
                    :showTotal="showTotalFun"
                    :table-data="tableData"
                    :tableKey="tableKey"
                    :total-items="totalItems"
                    :columns="columns"
                    :hide-row-selection="true"
                    :is-full="true"
                    :current-page="pageNumber"
                    @emitDeleteItem="deleteItem"
                    @emitEdit="openForm"
                    @emitOnPageChange="updatePageChange"
                    @emitRefreshData="getData"
                    @emitSelectChange="updateSelectChange"
                    @emitShowSizeChange="updateShowSizeChange"
                    @emitSortData="updateSortData"
                > -->
                <ele-table
                    :columns="columns"
                    :table-data="tableData"
                    :total-items="totalItems"
                    :is-full="true"
                    :actionsType="actionsType"
                    :current-page="pageNumber"
                    :hide-row-selection="true"
                    @emitRefreshData="getData"
                    @emitSelectChange="updateSelectChange"
                    @emitOnPageChange="updatePageChange"
                    @emitShowSizeChange="updateShowSizeChange"
                >
                <!-- 按钮 -->
                <a-row :gutter="8">
                    <a-col :span="6" class="gutter-row">
                        <a-input
                            :placeholder="l('SearchWithThreeDot')"
                            v-model.trim="filterText"
                        />
                    </a-col>

                    <a-col :span="4" class="gutter-row" >
                        <a-button @click="search" type="primary" v-if="isGranted('Pages.WorkflowSchemeInfo.Query')">
                            {{ l("Search") }}
                        </a-button>
                        <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                    </a-col>

                    <a-col :span="14" class="gutter-row" style="text-align: right;">
                        <a-button
                            @click="openForm('')"
                            type="primary"
                            v-if="isGranted('Pages.WorkflowSchemeInfo.Create')"
                        >
                            <a-icon type="plus"/>
                            {{ l("Create") }}
                        </a-button>

                    </a-col>
                </a-row>
            </ele-table>
        <a-drawer destroyOnClose width="800" title="流程详情" :visible="flowContainerCol.show" placement="right" :mask="true" @close="auditDrawClose"  >
            <workflow-container-draw  :code="targetScheme.code"    :mode="targetScheme.mode"  @whenClose="auditDrawClose" ></workflow-container-draw>
        </a-drawer>


    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalHelper} from "@/shared/helpers";
    import workflowSchemeApi from "@/shared/service-proxies/workflow/services/workflow-scheme-proxies";
    import workflowContainer  from "@/app/workflow/applications/container/index.vue"
    import form from "./form.vue";
    import nodeForm  from "./node/nodeForm";
    import objectHelper  from "../../../shared/helpers/objectHelper";
    import moment from "moment";
    import EleTable from "@/components/ele-table";
    import workflowContainerDraw from "@/app/workflow/applications/container/index-draw.vue";
    import workflowApi from "../../../shared/service-proxies/workflow/services/workflow-proxies";


    export default {
        mixins: [AppComponentBase],
        name: "workflow-scheme-info-index",
        components: {EleTable ,workflowContainerDraw },
        data() {
            return {
                flowContainerCol:{
                    show:false
                } ,
                targetScheme:{
                      mode:'create',
                     code :'',
                },
                columns:[
                    {
                        title: '模板名称',
                        dataIndex: "name",
                        sorter: true,
                        align: "center",
                        // width: '200',
                        // fixed: 'left',
                    },
                    {
                        title: '流程编号',
                        dataIndex: "code",
                        sorter: true,
                        align: "center",
                    },
                    {
                        title: '流程分类',
                        dataIndex: "category",
                        sorter: true,
                        align: "center",
                        width: '100',
                    },
                    {
                        title: '是否启用',
                        dataIndex: "enabledMarkStr",
                        sorter: true,
                        align: "center",
                    },
                    {
                        title: 'APP中是否可用',
                        dataIndex: "isInAppStr",
                        sorter: true,
                        align: "center",
                    },
                    {
                        title: '描述',
                        dataIndex: "description",
                        sorter: true,
                        align: "center",
                        ellipsis: true,
                        width: 300
                    },
                    {
                        title: this.l("Actions"),
                        dataIndex: "actions",
                        fixed: 'right',
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "actions" }
                    }
                ],
                curDataItemTree: [],
                queryModel:{
                    parentId:'',
                    name:''
                },
                spinning: false,
                // 搜索
                filterText: "",
                // 选择多少项
                selectedRowKeys: [],
                tableData: [],
                tableKey: "WorkflowSchemeInfoPagedDto",
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: {
                    sorting: "",
                    maxResultCount: 50,
                    skipCount: 0,
                },
                _serviceProxy: "",
                commonService: null,
                actionsType: {},
            };
        },
        created() {
            this.initActionsType();
            this.getData();
        },
        methods: {
            auditDrawClose(){
                this.flowContainerCol.show = false
            },

            search(){
                this.pageNumber = 1
                this.request.skipCount = 0
                this.getData()
            },
            /**
             * 获取数据
             */
            async getData() {
                this.spinning = true;
                workflowSchemeApi.getPagedList({
                    filterText: this.filterText,
                    sorting: this.request.sorting,
                    maxResultCount: this.request.maxResultCount,
                    skipCount: this.request.skipCount
                }).then((res)=>{
                    this.spinning = false;
                    this.tableData = res.items.map(item => {
                        return {
                            ...item,
                            enabledMarkStr: item.enabledMark ? '是' : '否',
                            isInAppStr: item.isInAppStr ? '是' : '否',
                        }
                    });
                    this.totalItems = res.totalCount;
                    // console.log(this.request.maxResultCount)
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    // console.log(this.totalPages)
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                    // console.log(  this.pagerange )

                }).catch((data,error)=>{
                    console.log(error)
                    this.spinning = false;
                })
                return
            },
            /**
             * table选择事件
             */
            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                console.log(this.selectedRows);
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * table选择事件
             */
            onSelectChange(selectedRowKeys, selectedRows) {
                this.selectedRowKeys = selectedRowKeys;
                this.selectedRows = selectedRows;
                console.log(this.selectedRows);
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 重置
             */
            refreshGoFirstPage() {
                this.filterText = "";
                this.request.skipCount = 0;
                this.getData();
            },
            /**
             * 新建修改
             */
            openForm(param) {
                let mode = 'create'
                 console.log(param)
                if (param!=''){
                    mode = 'edit'
                }else{
                }
                ModalHelper.create(
                    form,
                    {
                        mode:mode,
                        formModel:{
                            ...param
                        },
                    },
                    {
                        width: "1200px",
                        isChange:true
                    }
                ).subscribe((res) => {
                    console.log('messageRevecived')
                    console.log(res)
                    this.refreshGoFirstPage();
                });
            },

            /**
             * 清空
             */
            restCheckStatus() {
                this.selectedRowKeys = [];
            },
            /**
             * 单个删除
             */
            deleteItem(item) {
                console.log(item);
                console.log(item.item.id);
                let obj = {};
                obj.id = item.item.id;
                const body = JSON.stringify(obj);
                this.spinning = true;
                this._serviceProxy
                    .changeStatus(body)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(() => {
                        this.refreshGoFirstPage();
                        this.$notification["success"]({
                            message: this.l("SuccessfullyDeleted"),
                        });
                    });
            },
            /**
             * 导出为excel
             */
            exportToExcel() {
                this.spinning = true;
                this._serviceProxy.getToExcelFile().then((result) => {
                    this.spinning = false;
                    fileDownloadService.downloadTempFile(result);
                });
            },
            /*
             * 新建文章
             */
            /**
             * emit新建修改
             */
            edit(newV) {
                console.log(newV);
                let {id} = newV;
                this.openForm(id);
            },

            /**
             * 排序更新data
             */
            updateSortData(newV) {
                let {columnKey, order} = newV;
                this.request.sorting = order ? `${columnKey} ${order}` : "";
                this.getData();
            },
            /**
             * 分页
             */
            updatePageChange(newV) {
                let {page, pageSize} = newV;
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                let {current, size} = newV;
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * table选择事件
             */
            updateSelectChange(newV) {
                console.log(newV);
                let {selectedRowKeys, selectedRows} = newV;
                this.selectedRowKeys = selectedRowKeys;
            },
            simulate(data){
                this.targetScheme.code = data.code
                console.log(this.targetScheme)
                this.flowContainerCol.show = true

            },
            /**
             * 初始化需要显示的func btn
             */
            initActionsType() {
                let _that = this
                let obj = {
                    type: "sys-interface-action",
                    isShow: true,
                    fns: {
                        dropList:[{
                            granted: this.isGranted('Pages.WorkflowSchemeInfo.Edit'), name:'修改', icon: "profile", fn: (data) => {
                                _that.openForm(data)
                            },
                        },{
                            granted: this.isGranted('Pages.WorkflowSchemeInfo.Simulation'), name:'模拟发起', icon: "profile", fn: (data) => {
                                _that.simulate(data)
                            }
                        }],
                        //check: { granted: true, name: this.l("查看") },
                        //create: { granted: true, name: "新建" },
                        //delete: { granted: true, name: this.l("Delete") },
                    },
                };
                this.actionsType = obj;
            },
        },
    };
</script>

<style lang="less" scoped>
    .btn--container {
        margin-bottom: 20px;
    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    .table-plus,
    .table-delete {
        margin-left: 10px;
    }
</style>
