<template>
  <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span span v-if="mode !== 'create'"
          >{{ mode === "audit" ? "审核" : "" }}{{ processInfo.title }}</span
        >
        <span span v-if="mode === 'create'"
          >发起 {{ formSchemeInfo.name }}</span
        >
      </div>
    </div>
    <a-form v-if="mode === 'create'">
      <a-form-item label="流程标题">
        <a-input v-model="title"></a-input>
      </a-form-item>
    </a-form>

        <a-tabs :active-key="activeTab" @tabClick="tabClick">
            <a-tab-pane :key="item.id" v-for="item in workflowForms" forceRender>
        <span slot="tab">
          {{ item.name }}
        </span>
        <zr-form-build
          @submit="save"
          :value="item.scheme.formDefined"
          :ref="item.id"
        />
      </a-tab-pane>

            <a-tab-pane key="2" v-if="isShowTab">
                <span slot="tab"> 流转记录 </span>
                <a-timeline>
                    <a-timeline-item v-for="(item, index) in timeLines" :key="index"
                    >{{ item.title }}
                    </a-timeline-item>
                </a-timeline>
            </a-tab-pane>

      <a-tab-pane key="3" v-if="isShowTab" forceRender>
        <span slot="tab"> 流程信息 </span>
        <div
          id="workflowshcemeinfo"
          class="tab-pane"
          style="position: relative; padding: 5px; height: 600px"
        >
          <div
            style="
              position: relative;
              width: 100%;
              height: 100%;
              border: 1px solid #ccc;
              padding-bottom: 40px;
            "
                    >
                        <div id="wf_shcemeinfo"></div>
                        <div  style="
                position: absolute;
                left: 0px;
                bottom: 0;
                width: 100%;
                height: 40px;
                border-top: 1px solid #ddd;
                line-height: 39px;
                text-align: right;
                padding-right: 10px;
                font-size: 16px;
              "
                        >
                            <div style="display: inline-block; margin-left: 10px">
                                <i
                                        style="padding-right: 5px; color: #5bc0de"
                                        class="fa fa-square"
                                ></i
                                ><span>正在处理</span>
                            </div>
                            <div style="display: inline-block; margin-left: 10px">
                                <i
                                        style="padding-right: 5px; color: #5cb85c"
                                        class="fa fa-square"
                                ></i
                                ><span>已处理</span>
                            </div>
                            <div style="display: inline-block; margin-left: 10px">
                                <i
                                        style="padding-right: 5px; color: #999"
                                        class="fa fa-square"
                                ></i
                                ><span>未处理</span>
                            </div>
                        </div>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>

        <!--        <div v-if="workflowForms.length == 1 ">-->
        <!--            <h2> {{workflowForms[0].name}}</h2>-->
        <!--            <zr-form-build @submit="save" :value="workflowForms[0].scheme.formDefined" :ref="workflowForms[0].id"/>-->
        <!--        </div>-->
      <div class="modal-footer" v-if="mode !== 'view'">
            <a-button v-if="mode === 'create'" type="primary" @click="createFlow"
            >发起流程
            </a-button
            >
            <a-button
                    v-if="mode === 'audit'"
                    data-opCode="disagree"
                    data-opName="不同意"
                    type="danger"
                    @click="auditFlowStart"
            >不同意
            </a-button
            >
            <a-button
                    v-if="mode === 'audit'"
                    data-opCode="agree"
                    data-opName="同意"
                    type="primary"
                    @click="auditFlowStart"
            >同意
            </a-button
            >
        </div>
    </a-spin>
</template>

<script>
    import {ZrFormBuild} from "../../../../components";
    import formSchemeApi from "@/shared/service-proxies/formScheme/services/form-scheme-proxies";
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    import {message} from "ant-design-vue";
    import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
    import sysInterfaceApi from "@/shared/service-proxies/sysInterface/services/sys-interface-proxies";
    import workflowSchemeApi from "@/shared/service-proxies/workflow/services/workflow-scheme-proxies";
    import workflowApi from "@/shared/service-proxies/workflow/services/workflow-proxies";
    import objectHelper from "@/shared/helpers/objectHelper";
    import entityHelper from "@/shared/helpers/EntityHelper.js";
    import workflowProcessApi from "@/shared/service-proxies/workflow/services/workflow-process-proxies";
    // status  loaded  -> collectingData -> verifying -> verifyFailed  -> verifySuccess
    export default {
        name: "workflow-container",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                activeTab: "",
                isShowTab: true,
                code: "",
                spinning: true,
                mode: "create",
                taskId: "",
                task: {},
                title: "",
                formSchemeInfo: {},
                workflowForms: [],
                processId: "",
                timeLines: [],
                workflowshcemeinfo: {},
                processInfo: {
                    id: "",
                    processNo: "",
                    processId: "",
                    curNode: {},
                    flowForms: [],
                },
                creationInfo: {
                    creatorName: "",
                    creatorDep: "",
                    createdTime: "",
                },
                auditors: {},
                formsData: [],
            };
        },
        mounted() {
            this.initWorkflowDesigner();
        },
        created() {
            this.fullData();
            this.init();
        },
        components: {ZrFormBuild},
        methods: {
            //容器初始化
            init() {
                console.log(this.mode);
                switch (this.mode) {
                    //创建流程
                    case "create":
                        //获取流程模板
                        workflowSchemeApi.getByCode(this.code).then((flowInfo) => {
                            console.log(flowInfo);
                            this.formSchemeInfo = flowInfo;
                        });
                        workflowSchemeApi.getStartNode(this.code).then((nodeInfo) => {
                            //this.workflowForms =  nodeInfo.wfForms
                            this.initForms(nodeInfo.wfForms);
                            this.isShowTab = false;
                            this.spinning = false;
                        });
                        break;
                    case "audit": {
                        //获取当前流程实例
                        let task = this.task;
                        this.processInfo.title = task.title;
                        this.processInfo.id = task.processId;
                        this.processId = task.processId;
                        this.taskId = task.taskId;
                        workflowProcessApi
                            .getProcessDetails(task.processId, task.taskId)
                            .then((detail) => {
                                console.log(detail);
                                let info = detail.info;
                                let schemeObj = JSON.parse(info.scheme);
                                this.loadTimeLine(detail.task || [], info || {});
                                this.loadFlowInfo(schemeObj, detail.task || [], info || {});
                                console.log(this.workflowForms);
                                //获取当前节点
                                let curNode = workflowSchemeApi.getNode(
                                    JSON.parse(detail.info.scheme),
                                    task.nodeId
                                );
                                console.log(curNode);
                                //获取当前节点表单
                                let curNodeForms = curNode.wfForms;
                                //加载表单了
                                this.initForms(curNodeForms);
                                setTimeout(this.setFormsData, 1000);
                                setTimeout(() => {
                                    this.spinning = false;
                                }, 2000);
                            });
                        // //获取当前节点
                        // //获取表单和数据
                        // workflowSchemeApi.getNodeInfo(this.code).then((nodeInfo) => {
                        //     //this.workflowForms =  nodeInfo.wfForms
                        //     this.initForms(nodeInfo.wfForms)
                        // })
                        // workflowSchemeApi.getStartNode(this.code).then((nodeInfo) => {
                        //     //this.workflowForms =  nodeInfo.wfForms
                        //     this.initForms(nodeInfo.wfForms)
                        // })
                        break;
                    }
                    case "view": {
                        console.log("view!!!");
                        console.log(this.task);
                        //获取当前流程实例
                        let task = this.task;
                        this.processInfo.title = task.title;
                        this.processInfo.id = task.processId;
                        this.processId = task.processId;
                        this.taskId = task.taskId;
                        workflowProcessApi
                            .getProcessDetails(task.processId, task.taskId)
                            .then((detail) => {
                                console.log(detail);
                                let info = detail.info;
                                let schemeObj = JSON.parse(info.scheme);
                                this.loadTimeLine(detail.task || [], info || {});
                                this.loadFlowInfo(schemeObj, detail.task || [], info || {});
                                console.log(this.workflowForms);
                                //获取当前节点
                                let curNode = workflowSchemeApi.getNode(
                                    JSON.parse(detail.info.scheme),
                                    task.nodeId
                                );
                                console.log(curNode);
                                //获取当前节点表单
                                let curNodeForms = curNode.wfForms;
                                //加载表单了
                                this.initForms(curNodeForms);
                                setTimeout(this.setFormsData, 2000);
                                setTimeout(() => {
                                    this.spinning = false;
                                }, 2000);
                            });
                    }
                        // //获取当前节点
                        // //获取表单和数据
                        // workflowSchemeApi.getNodeInfo(this.code).then((nodeInfo) => {
                        //     //this.workflowForms =  nodeInfo.wfForms
                        //     this.initForms(nodeInfo.wfForms)
                        // })
                        // workflowSchemeApi.getStartNode(this.code).then((nodeInfo) => {
                        //     //this.workflowForms =  nodeInfo.wfForms
                        //     this.initForms(nodeInfo.wfForms)
                        // })
                        break;
                    default:
                        break;
                }
            },
            initForms(forms) {
                let that = this;
                for (let form in forms) {
                    formSchemeApi.getInfo(forms[form].info.id).then((formInfo) => {
                        console.log(formInfo);
                        let schemeJson = formInfo.scheme.scheme;
                        let scheme = JSON.parse(schemeJson);
                        // todo this.interfaces = 接口相关
                        console.log(that.workflowForms);
                        this.workflowForms.push({
                            id: forms[form].id,
                            schemeId: formInfo.schemeId,
                            name: formInfo.name,
                            scheme: scheme,
                            status: "loaded",
                        });
                        if (form == 0) {
                            this.activeTab = forms[form].id;
                        }
                    });
                }
            },
            async auditFlowStart(e) {
                let opCode = e.target.dataset.opcode;
                let opName = e.target.dataset.opcName;
                let waiter = await this.saveFormsData();
               let saved = await waiter(this.formsData,this.auditFlow.bind(this,opCode,opName))
            },
            async auditFlow(opCode,opName) {
                    await workflowApi.auditFlow(
                        opCode,
                        opName,
                        this.processId,
                        this.taskId,
                        "审核意见",
                        this.auditors
                    );
                    message.success("审核成功");
                    this.close();
            },
            setFormsData(forms) {
                for (let index in this.workflowForms) {
                    let curForm = this.workflowForms[index];
                    let schemeId = curForm.schemeId;
                    formSchemeApi
                        .getInstanceForm(schemeId, this.processInfo.id)
                        .then((formData) => {
                            this.$refs[curForm.id][0].setData(formData);
                        });
                }
            },
            getData(id) {
                return this.$refs[id][0].getData();
            },
            async createFlow() {
                //收集表单数据
                let waiter = await this.collectionFormData();
                let valid = await waiter(this.workflowForms);

                if (valid) {
                    //表单全部验证通过
                    let result = await workflowApi.create(
                        this.code,
                        false,
                        this.title,
                        0,
                        this.auditors,
                        this.formsData
                    );
                    this.close()
                }
            },
            tabClick(tab) {
                this.activeTab = tab;
            },
            async saveFormsData() {
                //收集表单数据
                let waiter = await this.collectionFormData();
                let valid = await waiter(this.workflowForms);
              //  console.log(valid);
                if (valid) {
                    //表单全部验证通过
                    for (let i in this.formsData) {
                        let curForm = this.formsData[i]
                        curForm.status = 'saving'
                        formSchemeApi.saveInstanceForm(curForm.schemeId, this.processId, curForm.fromData)
                            .then((result) => {
                                let savedForm = this.formsData.find((item) => {
                                    return item.schemeId == curForm.schemeId
                                })
                                savedForm.status = "saveSuccess"
                            }).catch((ex => {
                            let failedForm = this.formsData.find((item) => {AddTransient
                                return item.schemeId == curForm.schemeId
                            })
                            failedForm.status = "saveFailed"
                        }));
                    }
                    let that = this
                    return async function checkSavedStatus(formsData,callback) {
                        // status  loaded  -> collectingData -> verifying -> verifyFailed  -> verifySuccess  saving-> saveSuccess -> saveFailed
                        //正在收集表单数据
                        let passed = true;
                        for (let form in formsData) {
                              console.log(form)
                              console.log(formsData[form])
                             console.log(formsData[form].status)
                            if (formsData[form].status == "saving") {
                                passed = false;
                            }
                            if (formsData[form].status == "saveFailed") {
                                passed = false;
                                message.error("表单保存失败！");
                                return false;
                            }
                        }
                        if (!passed) {
                            console.log("！！！！self call");
                             console.log(callback)
                            return setTimeout(checkSavedStatus.bind(that, formsData,callback), 200);
                        } else {
                             setTimeout(callback,1000)
                            return true;
                        }
                    };
                }


            },
            async collectionFormData() {
                this.formsData = [];
                //收集表单数据
                for (let i in this.workflowForms) {
                    let curWorkflowForm = this.workflowForms[i];
                    //重置表单状态
                    curWorkflowForm.status = "collectingData";
                    this.getData(this.workflowForms[i].id)
                        .then((data) => {
                            curWorkflowForm.status = "verifySuccess";
                            this.formsData.push({
                                schemeId: curWorkflowForm.schemeId,
                                mainTableId: "",
                                fromData: data,
                                //状态为unStaged 表示为未保存
                                status: "unStaged"
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            //验证失败
                            curWorkflowForm.status = "verifyFailed";
                        });
                    let that = this;
                    return async function checkStatus(workflowForms) {
                        // status  loaded  -> collectingData -> verifying -> verifyFailed  -> verifySuccess
                        //正在收集表单数据
                        let passed = true;
                        for (let form in workflowForms) {
                            if (
                                workflowForms[form].status == "collectingData" ||
                                workflowForms[form].status == "verifying"
                            ) {
                                passed = false;
                            }
                            if (workflowForms[form].status == "verifyFailed") {
                                passed = false;
                                message.error("表单验证失败，请检查后重新提交！");
                                return false;
                            }
                        }
                        if (!passed || that.formsData.length != that.workflowForms.length) {
                            console.log("self call");
                            return setTimeout(checkStatus.bind(that, workflowForms), 200);
                        } else {
                            return true;
                        }
                    };
                    console.log("call");
                }
            },
            loadTimeLine: function (taskInfo, info) {
                console.log(taskInfo);
                var nodelist = [];
                var history = info.taskLogList || [];
                // 当前节点处理人信息
                $.each(taskInfo, function (_index, _item) {
                    var nameList = [];
                    $.each(_item.workflowUserInfoList, function (_jindex, _jitem) {
                        if (_jitem.mark == 0) {
                            nameList.push(_jitem.id);
                        }
                    });

                    var point = {
                        title: _item.nodeName + "【正在处理节点】",
                        people: String(nameList),
                        content: "需要其审核",
                        time: "当前",
                    };
                    //Vue.set(this.nodelist, "", point);
                    nodelist.push(point);
                });
                // 历史审核记录
                var anodeinfo = {};
                var $anodeinfo = $(".tab-flow-audit");
                for (var i = 0, l = history.length; i < l; i++) {
                    var item = history[i];

                    var content = item.operationName;
                    if (item.des) {
                        content += "<br/>【审批意见】：" + item.des;
                    }

                    var nodeName = "";
                    if (item.F_NodeId && page.nodeMap[item.nodeId]) {
                        nodeName = page.nodeMap[item.nodeId].name;
                    }

                    var point = {
                        title: item.nodeName || nodeName,
                        people: item.createUserId,
                        peopleName: item.createUserName,
                        content: content,
                        time: item.createDate,
                    };

                    nodelist.push(point);
                }

                this.timeLines = nodelist;
                //$("#wf_timeline").lrtimeline(nodelist, isFinished);
            },
            loadFlowInfo: function (scheme, taskInfo, info) {
                console.log(info);
                console.log(taskInfo);
                var nodeInfoes = {};
                var strcurrentIds = String(info.currentNodeIds || []);
                var history = info.taskLogList || [];
                var nodeMap = {};
                // 当前节点处理人信息
                $.each(taskInfo, function (_index, _item) {
                    var nameList = [];
                    $.each(_item.workflowUserInfoList, function (_jindex, _jitem) {
                        if (_jitem.Mark == 0) {
                            nameList.push(_jitem.id);
                        }
                    });
                    var point = {
                        namelist: nameList,
                    };
                    nodeInfoes[_item.nodeId] = nodeInfoes[_item.nodeId] || [];
                    nodeInfoes[_item.nodeId].push(point);
                });
                // 初始化工作流节点历史处理信息
                $.each(history, function (id, item) {
                    nodeInfoes[item.nodeId] = nodeInfoes[item.nodeId] || [];
                    nodeInfoes[item.nodeId].push(item);
                });
                $.each(scheme.nodes, function (_index, _item) {
                    //0正在处理 1 已处理同意 2 已处理不同意 3 未处理
                    _item.state = "3";
                    if (nodeInfoes[_item.id]) {
                        _item.history = nodeInfoes[_item.id];
                        _item.state = "1";
                    }
                    if (strcurrentIds.indexOf(_item.id) > -1) {
                        _item.state = "0";
                    }
                    if (_item.isAllAuditor == "2") {
                        _item.name += "<br/>【多人审核:";

                        if (_item.auditorType == "1") {
                            _item.name += "并行】";
                        } else {
                            _item.name += "串行】";
                        }
                    }
                    nodeMap[_item.id] = _item;
                });
                console.log(scheme);
                $("#wf_shcemeinfo").lrworkflowSet("set", {data: scheme});
            },
            initWorkflowDesigner() {
                $("#wf_shcemeinfo").lrworkflow({
                    isPreview: true,
                    openNode: function (node) {
                        top.wflookNode = node;
                        if (node.history) {
                        }
                    },
                });
            },
            save(data, data2) {
                console.log(data, data2);
            },
        },
    };
</script>

<style scoped>
</style>