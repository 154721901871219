<template>


    <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
        <div class="modal-title">
            <a-icon type="medicine-box"/>
            <span>
                 流程图
            </span>
        </div>
    </div>
        <div
                id="workflowShcemeInfo"
                class="tab-pane"
                style="position: relative; padding: 5px; height: 600px"
        >
            <div style="
                      position: relative;
                      width: 100%;
                      height: 100%;
                      border: 1px solid #ccc;
                      padding-bottom: 40px;
                    "
            >
                <div id="wf_shcemeinfo"></div>
                <div style="
                        position: absolute;
                        left: 0px;
                        bottom: 0;
                        width: 100%;
                        height: 40px;
                        border-top: 1px solid #ddd;
                        line-height: 39px;
                        text-align: right;
                        padding-right: 10px;
                        font-size: 16px;
                      "
                >
                    <div style="display: inline-block; margin-left: 10px">
                        <i
                                style="padding-right: 5px; color: #5bc0de"
                                class="fa fa-square"
                        ></i
                        ><span>正在处理</span>
                    </div>
                    <div style="display: inline-block; margin-left: 10px">
                        <i
                                style="padding-right: 5px; color: #5cb85c"
                                class="fa fa-square"
                        ></i
                        ><span>已处理</span>
                    </div>
                    <div style="display: inline-block; margin-left: 10px">
                        <i
                                style="padding-right: 5px; color: #999"
                                class="fa fa-square"
                        ></i
                        ><span>未处理</span>
                    </div>
                </div>
            </div>
        </div>

    </a-spin>
</template>

<script>

    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";

    export default {
        name: "flow-viewer",
        mixins: [AppComponentBase, ModalComponentBase],
        data: () => {
            return {
                spinning:false,
                scheme: null
            }
        },
        created() {
            this.fullData()

        },
        mounted() {
            this.initWorkflowDesigner()
        }, methods: {
            initWorkflowDesigner() {
                $("#wf_shcemeinfo").lrworkflow({
                    isPreview: true,
                    openNode: function (node) {
                        top.wflookNode = node;
                        if (node.history) {
                        }
                    },
                });
                this.$nextTick(() => {
                    $("#wf_shcemeinfo").lrworkflowSet("set", {data: this.scheme});
                    this.spinning = false
                })

            },
        }
    }
</script>

<style scoped>

</style>